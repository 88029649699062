<template>
  <v-container class="fill-height">
    <v-row class="ma-10" justify="center" no-gutters >
      <v-col class="hidden-xs-only" sm="1" md="2">
      </v-col>
      <v-col md="auto">
        <v-row justify="center" no-gutters>
          <v-col xs="12" sm="5" md="6" lg="6" xl="6" align-self="center" class="text-center">
            <v-avatar size="10em">
              <v-img src="timo.jpeg"></v-img>
            </v-avatar>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Timo Vildersen
                </v-list-item-title>
                <v-list-item-subtitle>
                  Ego since 1982
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col xs="12" sm="7" md="6" lg="6" xl="6">
            <v-list-item>
              <v-list-item-action>
                <v-icon title="Volkswagen Arteon R">mdi-car</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn text :to="{name: 'Arteon'}">
                    Arteon R
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-icon title="BMW i4 M50">mdi-car</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn text>
                    BMW i4 M50
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-icon title="Volkswagen GTI">mdi-car</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn text :to="{name: 'GTI'}">
                    GTI
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-icon title="LinkedIn">mdi-linkedin</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn text target="_blank" href="https://www.linkedin.com/in/kivipallur/">
                    kivipallur
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-icon title="Facebook">mdi-alpha-f-box</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn text target="_blank" href="http://facebook.com/timo.vildersen">
                    timo.vildersen
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-icon title="Instagram">mdi-instagram</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn text target="_blank" href="https://www.instagram.com/timo.vildersen/">
                    @timo.vildersen
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-icon title="Goodreads">mdi-goodreads</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn text target="_blank" href="https://www.goodreads.com/user/show/113147858-timo">
                    Timo ( timov )
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="hidden-xs-only" sm="1" md="2">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
